<template>
  <Listbox
    as="div"
    :model-value="props.selectedClientIds"
    multiple
    class="sm:col-span-6"
    @update:model-value="onUpdate"
  >
    <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">{{
      label
    }}</ListboxLabel>
    <div class="relative mt-2">
      <ListboxButton
        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-300 sm:text-sm sm:leading-6"
      >
        <span class="flex items-center">
          <span class="block truncate">{{ selectedClientNames }}</span>
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="client in clients"
            :key="client.id"
            v-slot="{ active, selected }"
            as="template"
            :value="client.id"
          >
            <li
              :class="[
                active ? 'bg-primary-400 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <div class="flex items-center">
                <img
                  :src="client.displayImage"
                  alt=""
                  class="h-5 w-5 flex-shrink-0 rounded-full"
                />
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'ml-3 block truncate',
                  ]"
                  >{{ client.displayName }}</span
                >
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-primary-400',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  selectedClientIds: {
    type: Array as PropType<number[]>,
    required: true,
  },
});

const clientStore = useClientStore();
const { clients } = storeToRefs(clientStore);

const selectedClientNames = computed(() => {
  const selClientNamesMaybe = props.selectedClientIds
    .map((id) => {
      const client = clients.value.find((c) => c.id === id);
      return client?.displayName ?? "";
    })
    .filter((name) => name !== "")
    .join(", ");

  return selClientNamesMaybe === "" ? "None" : selClientNamesMaybe;
});

const emit = defineEmits(["update:selected"]);

const onUpdate = (value: number[]) => {
  emit("update:selected", value);
};
</script>
